define("ods-app/controllers/requerimiento/record/informacion", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/helpers/is-empresa", "ods-app/models/requerimiento-archivo", "ods-app/mixins/upload-helper"], function (_exports, _abstractModuleIndex, _isEmpresa, _requerimientoArchivo, _uploadHelper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend(_uploadHelper.default, {
    modelClass: _requerimientoArchivo.default,
    user: Ember.computed.alias('currentUser.user'),
    loader: Ember.inject.service(),
    ajaxService: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    reload: true,
    modelFilters: Ember.computed('record', function () {
      return [{
        selectedFilter: {
          filterName: 'requerimiento'
        },
        value: this.get('record.id') || -1
      }];
    }),
    factorhPuestoActions: Ember.computed(function () {
      return [{
        title: 'Cambiar',
        iconName: 'fa-pencil',
        action: this.actions.editFactorhPuesto.bind(this)
      }];
    }),
    documentosColumns: Ember.computed(function () {
      return Ember.A([{
        label: 'Nombre',
        valuePath: 'nombre',
        sortable: false,
        width: '100px'
      }, {
        label: 'Observaciones',
        valuePath: 'observaciones',
        sortable: false,
        width: '100px'
      }, {
        label: 'Fecha',
        valuePath: 'createdAtFormatted',
        sortable: false,
        width: '50px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '60px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.editarDocumento.bind(this)
          },
          descargarDocumento: {
            title: 'Descargar documento',
            buttonClass: 'btn-success',
            icon: 'download',
            action: this.downloadFile.bind(this)
          },
          delete: {
            title: 'Eliminar',
            buttonClass: 'btn-danger',
            icon: 'trash-o',
            action: this.actions.delete.bind(this)
          }
        }
      }]);
    }),

    reloadModel() {
      this.set('reload', true);
    },

    // fechaInicioLaboresHabilitado: computed(function() {
    //   return this.store.query('config', {
    //     filter: {
    //       codigo: 'FECHA_INICIO_LABORES_REQUERIMIENTO',
    //     }
    //   })
    //   .then((data) => {
    //     let config = data.get('firstObject');
    //     let valor = config.get('valor');
    //     if(!config.get('activo')) {
    //       return false;
    //     }
    //     return valor;
    //   });
    // }),
    // esUrgenteHabilitado: computed(function() {
    //   return this.store.query('config', {
    //     filter: {
    //       codigo: 'ES_URGENTE_REQUERIMIENTO',
    //     }
    //   })
    //   .then((data) => {
    //     let config = data.get('firstObject');
    //     let valor = config.get('valor');
    //     if(!config.get('activo')) {
    //       return false;
    //     }
    //     return valor;
    //   });
    // }),
    actions: {
      editFactorhPuesto() {
        if (this.record.get('isNew')) {
          this.toast.error('Guarde el registro para poder realizar esta acción.');
          return;
        }

        return this.changeset.validate().then(() => {
          if (!this.changeset.get('isValid')) {
            this.toast.error('Corrija los errores para poder realizar esta acción.');
            return;
          }

          this.transitionToRoute('requerimiento.record.informacion.factorh-puesto');
        });
      },

      onChangeProyecto(proyecto) {
        if (!proyecto) {
          return;
        }

        if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
          let tipoServicio = proyecto.get('servicioAdquirido.tipoServicio');

          if ((this.changeset.get('plaza') === 'EXISTENTE' || this.changeset.get('plaza') === 'SUSTITUCION_TEMPORAL') && tipoServicio === 'ADMINISTRACION_PLANILLA_FACTORH') {
            this.loader.setIsLoading();
            this.ajaxService.request(`/cliente/${proyecto.get('cliente.id')}/proyecto-candidato-aplicacion`).then(data => {
              this.set('proyectoCandidatosYAplicaciones', data);
            }).catch(error => {
              this.toast.error('Ocurrió un error al obtener los candidatos.');
              throw error;
            }).finally(() => this.loader.setNotLoading());
          }
        } else if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'DEMO') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
          let perfilesPuestoAsociados = this.user.get('perfilPuestos');
          let perfilesPuestoAsociadosIds = null;

          if (perfilesPuestoAsociados) {
            perfilesPuestoAsociadosIds = perfilesPuestoAsociados.map(p => p.id);
          }

          this.set('clientePerfilesPuesto', this.store.query('cliente-perfil-puesto', {
            filter: {
              cliente: proyecto.get('cliente.id'),
              id: perfilesPuestoAsociadosIds
            },
            include: 'rangoSalarial'
          }));
        }

        if (!this.changeset.get('fechaInicioLabores')) {
          this.changeset.set('fechaInicioLabores', proyecto.get('fechaAlta'));
        }

        let clienteContacto = proyecto.get('clienteContacto');

        if (clienteContacto.get('id')) {
          this.changeset.set('responsableSolicitud', clienteContacto.get('nombre'));
          this.changeset.set('responsablePuesto', clienteContacto.get('puesto'));
          this.changeset.set('responsableCorreo', clienteContacto.get('correo'));
          this.changeset.set('responsableTelefono', clienteContacto.get('telefono'));
        }
      },

      searchProjects(text) {
        if (!text || text.length < 3) {
          return;
        }

        if (this.contactsQuery) {
          Ember.run.cancel(this.contactsQuery);
        }

        let userProyectosIds = this.user.get('proyectos').map(p => p.id);
        this.contactsQuery = Ember.run.later(() => {
          this.set('availableProjects', this.store.query('proyecto', {
            filter: {
              'estatus': this.constants.PROJECT_STATUS_ACTIVE,
              'servicioAdquirido.tipoServicio': ['CON_RECLUTAMIENTO_SELECCION', 'CON_RECLUTAMIENTO_SELECCION_FACTORH', 'ADMINISTRACION_PLANILLA_FACTORH'],
              nombreProyecto: text,
              id: userProyectosIds
            },
            include: 'servicioAdquirido,clienteContacto'
          }));
        }, 500);
      },

      onChangePlaza(changeset, propertyName, plaza = null) {
        this.changeset.set('plaza', plaza);

        if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM')) {
          let proyecto = this.changeset.get('proyecto');

          if (!proyecto.get('id')) {
            return;
          }

          if (plaza === 'EXISTENTE' || plaza === 'SUSTITUCION_TEMPORAL' && proyecto.get('servicioAdquirido.tipoServicio') === 'ADMINISTRACION_PLANILLA_FACTORH') {
            this.loader.setIsLoading();
            this.ajaxService.request(`/cliente/${proyecto.get('cliente.id')}/proyecto-candidato-aplicacion`).then(data => {
              this.set('proyectoCandidatosYAplicaciones', data);
            }).catch(error => {
              this.toast.error('Ocurrió un error al obtener los candidatos.');
              throw error;
            }).finally(() => this.loader.setNotLoading());
          }
        } else if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'DEMO')) {
          let tiposPlaza = [];

          if (plaza == 'NUEVA' || plaza == 'REEMPLAZO') {
            tiposPlaza.push({
              key: 'PLANILLA',
              name: 'Planilla'
            }, {
              key: 'FACTURACION_INTERNA_12_MESES',
              name: 'Facturación interna 12 meses'
            }, {
              key: 'FACTURACION_INTERNA_14_MESES',
              name: 'Facturación interna 14 meses'
            });
          } else if (plaza == 'FREELANCE') {
            tiposPlaza.push({
              key: 'FACTURACION_EXTERNA',
              name: 'Factura externa'
            });
          } else if (plaza == 'TEMPORAL') {
            tiposPlaza.push({
              key: 'FACTURACION_INTERNA_12_MESES',
              name: 'Facturación interna 12 meses'
            });
          }

          Ember.set(this.model, 'tiposPlaza', tiposPlaza);
        } else if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
          let tiposPlaza = this.constants.HIRING_TYPES_SERVINOVA;
          let cliente = changeset.get('proyecto').get('cliente');

          if (cliente && cliente.get('esExterno')) {
            tiposPlaza = tiposPlaza.filter(data => data.key !== 'PLANILLA_FACTURACION');
          }

          Ember.set(this.model, 'tiposPlaza', tiposPlaza);
        }
      },

      onChangePerfilPuesto(changeset, propertyName, perfilPuesto = null) {
        this.changeset.set('clientePerfilPuesto', perfilPuesto);

        if (!perfilPuesto) {
          return;
        }

        Ember.setProperties(this.changeset, {
          nombrePlaza: perfilPuesto.get('nombrePuesto'),
          formacionAcademica: perfilPuesto.get('formacionAcademica'),
          formacionAcademicaCompleta: perfilPuesto.get('formacionAcademicaCompleta'),
          gradoAcademico: perfilPuesto.get('gradoAcademico'),
          observacionesGradoAcademico: perfilPuesto.get('observacionesGradoAcademico'),
          maestria: perfilPuesto.get('maestria'),
          maestriaEstatus: perfilPuesto.get('maestriaEstatus'),
          maestriaObservaciones: perfilPuesto.get('maestriaObservaciones'),
          otrosCursos: perfilPuesto.get('otrosCursos'),
          otrosCursosObservaciones: perfilPuesto.get('otrosCursosObservaciones'),
          colegiatura: perfilPuesto.get('colegiatura'),
          cantidadTotalExperiencia: perfilPuesto.get('cantidadTotalExperiencia'),
          aniosTipoExperiencia: perfilPuesto.get('aniosTipoExperiencia'),
          situacionAcademica: perfilPuesto.get('situacionAcademica'),
          disponibilidadViaje: perfilPuesto.get('disponibilidadViaje'),
          horarioTrabajo: perfilPuesto.get('horarioTrabajo'),
          frecuencia: perfilPuesto.get('frecuencia'),
          cambioResidencia: perfilPuesto.get('cambioResidencia'),
          periodosEspecialesTrabajo: perfilPuesto.get('periodosEspecialesTrabajo'),
          beneficios: perfilPuesto.get('beneficios'),
          nombreJefeInmediato: perfilPuesto.get('jefeInmediatoAdministrativo')
        });
      },

      editarDocumento(documento) {
        let editRoute = 'requerimiento.record.informacion.documentos'; // If the item is new, transition with the whole object (wont trigger the
        // model hook), otherwise transition with its id (will trigger the model hook)

        if (Ember.isNone(documento.get('id'))) {
          this.transitionToRoute(editRoute, documento);
          return;
        }

        this.transitionToRoute(editRoute, documento.get('id'));
      }

    }
  });

  _exports.default = _default;
});