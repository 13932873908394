define("ods-app/routes/requerimiento/record/ingreso/asociar-planilla", ["exports", "servir-ember-utilities/routes/abstract-module-record", "ember-changeset-validations/validators"], function (_exports, _abstractModuleRecord, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleRecord.default.extend({
    modelName: 'proyecto-candidato',
    routeRecordIdentifierDynamicSegment: 'proyectocandidato_id',
    indexRoute: 'requerimiento.record.ingreso',
    ajaxService: Ember.inject.service(),
    proyectoId: null,
    aplicacionId: null,
    validations: Ember.computed(function () {
      return {
        requerimiento: (0, _validators.validatePresence)({
          presence: true
        }),
        proyecto: (0, _validators.validatePresence)({
          presence: true
        }),
        candidato: (0, _validators.validatePresence)({
          presence: true
        }),
        fechaAlta: (0, _validators.validatePresence)({
          presence: true
        }),
        factorhTipoPlanilla: (0, _validators.validatePresence)({
          presence: true
        }),
        factorhPuesto: (0, _validators.validatePresence)({
          presence: true
        }) // numeroSeguroSocial: validatePresence({ presence: true }),

      };
    }),
    usaMultiplesEmpresas: Ember.computed(function () {
      return this.currentUser.hasConfig('USA_MULTIPLES_EMPRESAS_FACTORH_POR_CLIENTE');
    }),
    relatedModelsToQuery: Ember.computed('proyectoId', 'aplicacionId', function () {
      let relatedModels = [];

      if (this.proyectoId) {
        relatedModels.push({
          name: 'proyectosPlaceHolder',
          modelName: 'proyecto',
          queryParams: {
            filter: {
              id: this.proyectoId
            }
          }
        });
      }

      if (this.aplicacionId) {
        relatedModels.push({
          name: 'aplicacionesPlaceHolder',
          modelName: 'aplicacion',
          queryParams: {
            filter: {
              id: this.aplicacionId
            },
            include: 'candidato'
          }
        });
      }

      return relatedModels;
    }),

    model(params, transition) {
      let requerimiento = this.modelFor('requerimiento.record').record;

      if (!requerimiento.id || !requerimiento.get('proyecto.id')) {
        this.toast.error('Guarde el requerimiento y asigne un proyecto para poder realizar esta acción.');
        return this.transitionTo(this.indexRoute);
      }

      let proyecto = requerimiento.get('proyecto');

      if (!this.usaMultiplesEmpresas && !proyecto.get('factorhDepartamentoStr')) {
        this.toast.error('El proyecto no tiene asignado un departamento de FactoRH.');
        return this.transitionTo(this.indexRoute);
      }

      this.set('proyectoId', requerimiento.get('proyecto.id'));
      this.set('aplicacionId', transition.queryParams.aplicacionId);

      if (!this.aplicacionId) {
        this.toast.error('Seleccione una aplicación para realizar esta acción.');
        return this.transitionTo(this.indexRoute);
      }

      this.loader.setIsLoading();

      if (this.usaMultiplesEmpresas) {
        let clienteFactorhEmpresas = proyecto.get('cliente').get('factorhEmpresas');
        return this._super(...arguments).then(parentData => {
          return Ember.RSVP.hash({
            factorhEmpresas: this.ajaxService.request('/factorh-empresas').then(empresasRes => {
              return empresasRes.data.filter(factorhEmpresaObj => clienteFactorhEmpresas.includes(factorhEmpresaObj.abreviatura));
            }),
            factorhTiposPlanilla: Ember.A([]),
            factorhDepartamentos: Ember.A([]),
            factorhPuestos: Ember.A([])
          }).then(factorhData => Ember.merge(parentData, factorhData)).finally(() => this.loader.setNotLoading());
        });
      }

      return this._super(...arguments).then(parentData => {
        return Ember.RSVP.hash({
          factorhPuestos: this.ajaxService.request('/factorh-puestos', {
            data: {
              requerimientoId: requerimiento.id
            }
          }).then(puestosRes => puestosRes.data),
          factorhTiposPlanilla: this.ajaxService.request('/factorh-tipos-planillas', {
            data: {
              requerimientoId: requerimiento.id
            }
          }).then(tiposPlanillaRes => tiposPlanillaRes.data)
        }).then(factorhData => Ember.merge(parentData, factorhData));
      }).finally(() => this.loader.setNotLoading());
    },

    createRecordInstance()
    /*params, transition*/
    {
      let requerimiento = this.modelFor('requerimiento.record').record;
      return this.store.createRecord(this.modelName, {
        requerimiento
      });
    },

    setupController(controller, model) {
      this._super(...arguments);

      let proyectoCandidato = model.record;

      if (!Ember.isNone(controller.get('proyectosPlaceHolder'))) {
        let proyecto = controller.get('proyectosPlaceHolder.firstObject');
        proyectoCandidato.set('proyecto', proyecto);
      }

      if (!Ember.isNone(controller.get('aplicacionesPlaceHolder'))) {
        let aplicacion = controller.get('aplicacionesPlaceHolder.firstObject');
        let fechaIngreso = aplicacion.get('fechaIngreso');
        let fechaSalida = aplicacion.get('fechaFinLabores');
        let candidato = aplicacion.get('candidato');
        proyectoCandidato.set('fechaAlta', fechaIngreso);
        proyectoCandidato.set('fechaBaja', fechaSalida);
        proyectoCandidato.set('candidato', candidato);
      }
    },

    actions: {
      willTransition() {
        return false;
      }

    }
  });

  _exports.default = _default;
});