define("ods-app/controllers/requerimiento-historico", ["exports", "servir-ember-utilities/controllers/abstract-module-index", "ods-app/models/requerimiento", "ods-app/helpers/is-empresa"], function (_exports, _abstractModuleIndex, _requerimiento, _isEmpresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _abstractModuleIndex.default.extend({
    user: Ember.computed.alias('currentUser.user'),
    modelName: 'requerimiento',
    modelClass: _requerimiento.default,
    editRoute: 'requerimiento.record',
    currentUser: Ember.inject.service(),
    modelSortingProperties: Ember.computed(function () {
      return Ember.A([{
        propertyName: 'fechaSolicitud'
      }]);
    }),
    requerimientoGlobalFilters: Ember.computed('user', function () {
      let userProyectosIds = this.user.get('proyectos').map(p => p.id);
      return [{
        selectedFilter: {
          filterName: 'proyecto'
        },
        value: userProyectosIds
      }];
    }),
    completedModelFilters: Ember.computed(function () {
      let requerimientosFilters = [...this.get('requerimientoGlobalFilters')];
      requerimientosFilters.pushObject({
        selectedFilter: {
          filterName: 'estatus'
        },
        value: 'CONTRATADO'
      });
      return requerimientosFilters;
    }),
    reprocessingModelFilters: Ember.computed(function () {
      let requerimientosFilters = [...this.get('requerimientoGlobalFilters')];
      requerimientosFilters.pushObject({
        selectedFilter: {
          filterName: 'estatus'
        },
        value: 'REPROCESO'
      });
      return requerimientosFilters;
    }),
    canceledModelFilters: Ember.computed(function () {
      let requerimientosFilters = [...this.get('requerimientoGlobalFilters')];
      requerimientosFilters.pushObject({
        selectedFilter: {
          filterName: 'estatus'
        },
        value: 'CANCELADO'
      });
      return requerimientosFilters;
    }),
    columns: Ember.computed(function () {
      let columns = [{
        label: 'Código',
        valuePath: 'codigo',
        sortable: false,
        width: '90px',
        filtrable: true,
        filterName: 'codigo'
      }, {
        label: 'Fecha de solicitud',
        valuePath: 'fechaSolicitudFormatted',
        sortable: false,
        width: '150px'
      }, {
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: false,
        width: '100px'
      }, {
        label: 'Reclutador',
        valuePath: 'reclutador.username',
        sortable: false,
        width: '150px',
        filtrable: true,
        filterName: 'reclutador'
      }, {
        label: 'Cliente',
        valuePath: 'proyecto.cliente.nombre',
        sortable: false,
        width: '150px',
        filtrable: true,
        filterName: 'proyecto.cliente.nombre'
      }];

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
        columns.push({
          label: 'Depto. de FactoRH',
          valuePath: 'proyecto.factorhDepartamentoStr',
          sortable: false,
          width: '100px',
          filtrable: true,
          filterName: 'proyecto.factorhDepartamentoStr'
        });
      }

      columns.push({
        label: 'Nombre de la plaza',
        valuePath: 'nombrePlaza',
        sortable: false,
        width: '160px',
        filtrable: true,
        filterName: 'nombrePlaza'
      }, {
        label: 'Fecha de finalización',
        valuePath: 'fechaFinalizacionFormatted',
        sortable: false,
        width: '150px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          }
        }
      });
      return Ember.A(columns);
    }),
    columnsCancelados: Ember.computed(function () {
      let columnsCancelados = [{
        label: 'Código',
        valuePath: 'codigo',
        sortable: false,
        width: '90px',
        filtrable: true,
        filterName: 'codigo'
      }, {
        label: 'Fecha de solicitud',
        valuePath: 'fechaSolicitudFormatted',
        sortable: false,
        width: '150px'
      }, {
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: false,
        width: '100px'
      }, {
        label: 'Reclutador',
        valuePath: 'reclutador.username',
        sortable: false,
        width: '150px',
        filtrable: true,
        filterName: 'reclutador'
      }, {
        label: 'Cliente',
        valuePath: 'proyecto.cliente.nombre',
        sortable: false,
        width: '150px',
        filtrable: true,
        filterName: 'proyecto.cliente.nombre'
      }];

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
        columnsCancelados.push({
          label: 'Depto. de FactoRH',
          valuePath: 'proyecto.factorhDepartamentoStr',
          sortable: false,
          width: '100px',
          filtrable: true,
          filterName: 'proyecto.factorhDepartamentoStr'
        });
      }

      columnsCancelados.push({
        label: 'Nombre de la plaza',
        valuePath: 'nombrePlaza',
        sortable: false,
        width: '160px',
        filtrable: true,
        filterName: 'nombrePlaza'
      }, {
        label: 'Razón de cancelación',
        valuePath: 'razonCancelacion.nombre',
        sortable: false,
        width: '150px'
      }, {
        label: 'Cancelado por',
        valuePath: 'usuarioCancelacion.username',
        sortable: false,
        width: '150px'
      }, {
        label: 'Fecha de cancelación',
        valuePath: 'fechaCancelacionFormatted',
        sortable: false,
        width: '150px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          }
        }
      });
      return Ember.A(columnsCancelados);
    }),
    columnsReproceso: Ember.computed(function () {
      let columnsReproceso = [{
        label: 'Código',
        valuePath: 'codigo',
        sortable: false,
        width: '90px',
        filtrable: true,
        filterName: 'codigo'
      }, {
        label: 'Fecha de solicitud',
        valuePath: 'fechaSolicitudFormatted',
        sortable: false,
        width: '150px'
      }, {
        label: 'Estatus',
        valuePath: 'statusToString',
        sortable: false,
        width: '100px'
      }, {
        label: 'Reclutador',
        valuePath: 'reclutador.username',
        sortable: false,
        width: '150px',
        filtrable: true,
        filterName: 'reclutador'
      }, {
        label: 'Cliente',
        valuePath: 'proyecto.cliente.nombre',
        sortable: false,
        width: '150px',
        filtrable: true,
        filterName: 'proyecto.cliente.nombre'
      }];

      if ((0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA')) {
        columnsReproceso.push({
          label: 'Depto. de FactoRH',
          valuePath: 'proyecto.factorhDepartamentoStr',
          sortable: false,
          width: '100px',
          filtrable: true,
          filterName: 'proyecto.factorhDepartamentoStr'
        });
      }

      columnsReproceso.push({
        label: 'Nombre de la plaza',
        valuePath: 'nombrePlaza',
        sortable: false,
        width: '160px',
        filtrable: true,
        filterName: 'nombrePlaza'
      }, {
        label: 'Fecha de seguimiento',
        valuePath: 'fechaSeguimientoProgramadaFormatted',
        sortable: false,
        width: '150px'
      }, {
        label: 'Fecha vencimiento',
        valuePath: 'ternaActiva.fechaVencimientoEnvioFormatted',
        sortable: false,
        width: '150px'
      }, {
        label: 'Acciones',
        cellComponent: 'tables/cell/object-actions',
        table: this,
        sortable: false,
        width: '100px',
        objectActions: {
          edit: {
            title: 'Editar',
            buttonClass: 'btn-primary',
            icon: 'pencil-square-o',
            action: this.actions.edit.bind(this)
          }
        }
      });
      return Ember.A(columnsReproceso);
    })
  });

  _exports.default = _default;
});