define("ods-app/controllers/requerimiento/record/terna/record", ["exports", "moment", "ods-app/helpers/is-empresa"], function (_exports, _moment, _isEmpresa) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    toast: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    datesCalculator: Ember.inject.service(),

    /**
     * Cantidad de días que tomó el proceso de envío de una terna:
     *   * Si es la primera terna entonces la cantidad
     *     de días entre la fecha de solicitud del requerimiento y la fecha de envío de
     *     la terna (o la de hoy).
     *
     *   * Si no es la primera terna entonces la cantidad de días entre la creación
     *     de la terna y la fecha de envío (o la de hoy)
     */
    cantidadDiasEnvioTerna: Ember.computed('changeset.{requerimiento.fechaSolicitud,fechaEnvioReal,createdAt}', function () {
      var comparacion = new Date();

      if (this.get('changeset.fechaEnvioReal')) {
        comparacion = this.get('changeset.fechaEnvioReal');
      }

      if (this.get('changeset.correlativo') === 1) {
        return this.get('datesCalculator').getCantidadDiasHabiles(this.get('changeset.requerimiento.fechaSolicitud'), comparacion);
      } else {
        return this.get('datesCalculator').getCantidadDiasHabiles(this.get('changeset.createdAt'), comparacion);
      }
    }),

    /**
     * Cantidad de días hábiles entre el envío real de la terna y la confirmación
     * de la terna
     */
    cantidadDiasEnvioConfirmacion: Ember.computed('changeset.{fechaEnvioReal,fechaConfirmacionReal}', function () {
      if (!this.changeset.get('fechaEnvioReal')) {
        return;
      }

      if (!this.changeset.get('fechaConfirmacionReal')) {
        return;
      }

      let diferencia = this.get('datesCalculator').getCantidadDiasHabiles(this.changeset.get('fechaEnvioReal'), this.changeset.get('fechaConfirmacionReal'));
      return diferencia;
    }),

    /**
     * Cantidad de días disponibles para la fecha de
     * vencimiento del envío de terna.
     *
     * @param  {[type]}  [description]
     * @return {[type]}  [description]
     */
    tiempoDisponibleTerna: Ember.computed('changeset.{fechaVencimientoEnvio,requerimiento,fechaAceptadaODeshabilitada,activo,aceptada}', function () {
      if (this.changeset.get('requerimiento.estatus') === 'CONFIRMADO_PENDIENTE_DE_INGRESO' || this.changeset.get('requerimiento.estatus') === 'REPROCESO' || this.changeset.get('requerimiento.estatus') === 'CONGELADO' || this.changeset.get('requerimiento.estatus') === 'CANCELADO') {
        return;
      }

      let fechaVencimiento = (0, _moment.default)(this.changeset.get('fechaVencimientoEnvio'));
      var comparacion = new Date();

      if (!this.changeset.get('activo') || this.changeset.get('aceptada')) {
        comparacion = (0, _moment.default)(this.changeset.get('fechaAceptadaODeshabilitada'));
      }

      return this.get('datesCalculator').getCantidadDiasHabiles(comparacion, fechaVencimiento);
    }),
    ternaCreatedAtFormatted: Ember.computed('changeset.createdAt', function () {
      if (!this.get('changeset.createdAt')) {
        return;
      }

      const momentDate = (0, _moment.default)(this.get('changeset.createdAt'));

      if (!momentDate.isValid()) {
        return '';
      }

      return momentDate.format('DD/MM/YYYY');
    }),
    actions: {
      eliminarApliaciondeTerna(aplicacion) {
        if (aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_COMPLETADO) {
          this.toast.error('No es posible regresar la aplicación ya que ha sido completada.');
          return;
        } else if (aplicacion.get('estatus') === this.constants.APPLICATION_STATUS_CONTRATADO) {
          this.toast.error('No es posible regresar la aplicación ya que ha sido contratada.');
          return;
        }

        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro de regresar la aplicación a Pre screening?`,
          message: null,
          onSubmit: () => {
            aplicacion.set('estatus', this.constants.APPLICATION_STATUS_PRE_SCREENING);
            aplicacion.set('terna', null);
            aplicacion.save();
          }
        });
      },

      rechazarTerna() {
        if (this.changeset.get('aplicaciones.length') < 1) {
          this.toast.error('No es posible rechazar la terna.');
          return;
        }

        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro de rechazar esta terna?`,
          message: null,
          onSubmit: () => {
            this.changeset.set('activo', false);
            this.changeset.set('fechaAceptadaDeshabilitada', new Date());
            this.transitionToRoute('requerimiento.record.terna.record', 'new');
          }
        });
      },

      aceptarTerna() {
        const requerimiento = this.changeset.get('requerimiento');
        let cantidadRequerida = 3;

        if (!this.changeset.get('fechaEnvioReal')) {
          this.toast.error('La fecha de envío real no puede estar vacía.');
          return;
        } else if (!this.changeset.get('fechaConfirmacionReal')) {
          this.toast.error('La fecha de confirmación real no puede estar vacía.');
          return;
        } else if (this.changeset.get('aceptada')) {
          this.toast.error('Esta terna ya fue aceptada.');
          return;
        }

        if (requerimiento.get('cantidadPlazas') > 3) {
          cantidadRequerida = requerimiento.get('cantidadPlazas');
        } // Aprobar ternas especiales con al menos 1 una aplicacion solo si es urgente el requerimiento


        if (this.get('currentUser').hasPermission('APPROVE_SPECIAL_TERNA') && (requerimiento.get('esUrgente') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'EL_TAIER') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'DEMO') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'SERVINOVA') || (0, _isEmpresa.isEmpresa)(this.currentUser.get('configs'), 'OPTIMUM'))) {
          cantidadRequerida = 1;
        }

        if (this.changeset.get('aplicaciones.length') < cantidadRequerida) {
          this.toast.error('No hay suficientes aplicaciones para aceptar la terna.');
          return;
        }

        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro de aceptar esta terna?`,
          message: null,
          onSubmit: () => {
            this.changeset.set('aceptada', true);
            this.changeset.set('fechaAceptadaDeshabilitada', new Date());
            this.changeset.save().then(() => {
              this.toast.success('Terna aceptada'); // Recargar el requerimiento porque el backend cambia su estatus.

              Ember.RSVP.resolve(requerimiento).then(requerimiento => {
                requerimiento.reload().then(() => {
                  this.transitionToRoute('requerimiento.record.ingreso');
                });
              });
            });
          }
        });
      },

      eliminarTerna() {
        let terna = this.store.peekRecord('terna', this.changeset.get('id'));
        let requerimiento = this.store.peekRecord('requerimiento', this.changeset.get('requerimiento.id'));
        let ultimaTerna = Ember.A(requerimiento.get('ternas').filterBy('activo', false)).get('lastObject');

        if (this.changeset.get('aplicaciones.length') > 0) {
          this.toast.error('Esta terna tiene aplicaciones asociadas.');
          return;
        }

        if (requerimiento.get('ternas').length <= 1) {
          this.toast.error('No se puede eliminar la única terna.');
          return;
        }

        this.send('openModal', 'popups/simple-confirm', {
          title: `¿Está seguro de eliminar esta terna?`,
          message: null,
          onSubmit: () => {
            terna.destroyRecord().then(() => {
              ultimaTerna.set('activo', true);
              ultimaTerna.save();
              this.transitionToRoute('requerimiento.record.informacion');
              this.toast.success('Terna eliminada correctamente.');
            }).catch(() => {
              this.toast.error('No se pudo eliminar la terna.');
            });
          }
        });
      }

    }
  });

  _exports.default = _default;
});